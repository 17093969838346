import {
  SET_HASHTAGS_LOADING,
  SET_HASHTAGS_ERROR,
  SET_HASHTAGS_SUCCESS
} from './actionTypes';

export const initialState = {
  loading: false,
  error: null,
  entries: []
};

const hashtagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HASHTAGS_LOADING:
      return { ...state, loading: action.loading };
    case SET_HASHTAGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error?.response || action.error || null
      };
    case SET_HASHTAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        entries: action.payload
      };
    default:
      return state;
  }
};
export default hashtagsReducer;
