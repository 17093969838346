import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useListenLocation = () => {
  const location = useLocation();
  const [locationArr, setLocationArr] = useState([]);

  useEffect(() => {
    setLocationArr([...locationArr, location?.pathname]);
}, [location]);

  return {
    locationArr
  };
};
export default useListenLocation;
