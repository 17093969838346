import { useState } from 'react';

const useToggle = (defaultValue = false) => {
  const [value, setValue] = useState(defaultValue);

  return {
    value,
    toggle: () => setValue(!value),
    unset: () => setValue(false),
    set: () => setValue(true),
    setValue
  };
};
export default useToggle;
