import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Tippy from '@tippyjs/react/headless';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
// Assets
import { ReactComponent as BurgerIcon } from '@assets/icons/burger.svg';
import { ReactComponent as UserBlueLightIcon } from '@assets/icons/user_blule_light.svg';
import { ReactComponent as UserBlueDarkIcon } from '@assets/icons/user_blue_dark.svg';
import { ReactComponent as UserWhiteLightIcon } from '@assets/icons/user_white_light.svg';
import { ReactComponent as UserWhiteDarkIcon } from '@assets/icons/user_white_dark.svg';

// Actions
import { logoutHelper } from '@reducers/users/actions';

// Hooks
import useToggle from '@hooks/useToggle';

// Components
import Logo from '@components/Logo';
import Button from '@components/Button';
import Menu from '@components/pages/home/Menu';

// Styles
import styles from './styles.scss';
import { withBreakpoints } from '@styles/breakpoints';
// Constants
import { ROUTES } from '../../utils/constants'

const Header = ({
  color = 'white',
  whiteHeaderBg = false,
  isTablet,
  isMobile,
  coursesCount,
  className,
  homePageLogo,
}) => {
  const { value: open, unset, set } = useToggle();
  const lastHash = useRef('');
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = useSelector(state => state.users.authorized);
  const { user } = useSelector(state => state?.users);
  const userDropdownOpen = useToggle();
  const dispatch = useDispatch();
  const navItems = [
    { hash: ROUTES?.price, text: 'Тарифы'},
    { hash: ROUTES?.forAuthors, text: 'Авторам' },
    { hash: ROUTES?.forUsers, text: 'Пользователям' },
    { hash: ROUTES?.forBusiness, text: 'Для бизнеса' }
  ];

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location.pathname]);

  useEffect(() => {
    let timer;
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (location.hash && lastHash.current && document.getElementById(lastHash.current)) {
      timer = setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest"
         });
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [location]);


  const toLogin = () => {
    navigate(ROUTES?.login);
  };

  const renderLaunchButton = (
    <Button
      to={isAuth ? "/creator/my-courses" : ROUTES?.register}
      classes={{
        root: classNames(
          styles.LaunchBusinessButtonWrapper,
          styles.LaunchBusinessButton,
          styles.LaunchBusinessButton_launch,
          {
            [styles.LaunchBusinessButton_launch_dark]: color !== 'black'
          }
        )
      }}
    >
      {isAuth ? 'Мои курсы' : 'Запустить курс'}
    </Button>
  );

  const renderLoginButton = (
    !isAuth && (
      <Button
        onClick={toLogin}
        classes={{
          wrapper: styles.LaunchBusinessButtonWrapper,
          root: classNames(
            styles.LaunchBusinessButton,
            styles.LaunchBusinessButton_login,
            {
              [styles.LaunchBusinessButton_login_dark]: color !== 'black'
            }
          )
        }}
      >
        Войти
      </Button>
    )
  );

  const avatar = user?.avatar?.image_url || localStorage.getItem('coacti_google_avatar');
  const renderAuthContent = isAuth && (
    <Tippy
      render={() => (
        <div className={styles.UserDropdown}>
          <div onClick={() => {
           if (window.location.pathname === '/profile' || window.location.pathname === '/profile/edit') {
             userDropdownOpen.unset()
           } else {
             navigate(ROUTES?.profile);
           }
          }} className={styles.UserDropdownItem}>
            Профиль
          </div>
          <div onClick={async() => await dispatch(logoutHelper())} className={styles.UserDropdownItem}>
            Выйти из аккаунта
          </div>
        </div>
      )}
      visible={userDropdownOpen.value}
      onClickOutside={userDropdownOpen.unset}
      interactive
      placement="bottom-end"
    >
      <div
        className={classNames(styles.UserDropdownWrapper, {
          [styles.UserDropdownWrapper_active]: userDropdownOpen.value
        })}
      >
        <div onClick={userDropdownOpen.toggle} className={styles.UserHeader}>
          {avatar
            ? (
              <div className={styles.AvatarWrapper}>
                <img src={avatar} alt="" className={styles.Avatar}/>
              </div>
            ) : (
              color === 'black' ? (
                !userDropdownOpen.value ? (
                  <UserBlueLightIcon className={styles.UserHeaderIcon} />
                ) : (
                  <UserBlueDarkIcon className={styles.UserHeaderIcon} />
                )
              ) : !userDropdownOpen.value ? (
                <UserWhiteLightIcon className={styles.UserHeaderIcon} />
              ) : (
                <UserWhiteDarkIcon className={styles.UserHeaderIcon} />
              )
            )
          }
          <div
            className={classNames(styles.UserHeaderShadow, {
              [styles.UserHeaderShadow_active]: userDropdownOpen.value
            })}
          />
        </div>
      </div>
    </Tippy>
  );

  return (
    <div
      className={classNames(
        styles.Wrapper,
        styles[`Wrapper_${color}`],
        className,
        {[styles.Wrapper__WhiteHeaderBg]: whiteHeaderBg}
      )}
    >
      <Menu open={open} onClose={unset} courses={navItems} />
      <div className={styles.Column}>
        <Logo color={color} homePageLogo />
      </div>
      <div className={styles.Column}>
        {!isTablet && !isMobile ? (
          <>
            <div className={styles.NavItems}>
              {navItems?.map((n, index) =>
                n?.url ? (
                  <NavLink
                    to={n?.url}
                    className={classNames(styles.NavItem)}
                    key={n?.text}
                    target={n.target || '_self'}
                  >
                    {n?.text}
                    {n?.count >= 0 ? (
                      <div className={styles.Counter}>{n?.count}</div>
                    ) : null}
                  </NavLink>
                ) : (
                  <HashLink
                    to={n?.hash}
                    className={classNames(styles.NavItem)}
                    key={n?.text}
                    target={n.target || '_self'}
                //     scroll={(el) => el.scrollIntoView({
                //       behavior: "smooth",
                //       block: "start",
                //       inline: "nearest"
                // })}
                  >
                    {n?.text}
                  </HashLink>
                )
              )}
            </div>
            {!isAuth ? (
              <div className={styles.Buttons}>
                {renderLaunchButton}
                {renderLoginButton}
              </div>
            ) : (
              <>
                {renderLaunchButton}
                {renderAuthContent}
              </>
            )}
          </>
        ) : (
          <>
            {isTablet && (
              <>
                {renderLaunchButton}
                {renderLoginButton}
              </>
            )}
            {renderAuthContent}
            <BurgerIcon onClick={set} className={styles.Burger} />
          </>
        )}
      </div>
    </div>
  );
};
export default withBreakpoints(Header);
