// @flow
// Libraries
import * as React from 'react';
import classNames from 'classnames';
// Hooks
import useToggle from '@hooks/useToggle';
// Styles
import styles from './styles.scss';
import { Link } from 'react-router-dom';

const Button = ({
  children,
  classes,
  fullWidth,
  width,
  height,
  rounded,
  light,
  htmlType = 'button',
  to,
  target = "_self",
  onClick,
  noShadow = false,
  disabled = false
}) => {
  const hovered = useToggle();

  return to ? (
    <Link
      to={to}
      className={classNames(styles.ButtonWrapper, classes?.wrapper, {
        [styles.Button_light]: light,
        [styles.Button_fullWidth]: fullWidth
      })}
      target={target}
    >
      <button
        onMouseEnter={hovered.set}
        onMouseLeave={hovered.unset}
        type={htmlType}
        onClick={onClick}
        className={classNames(styles.Root, classes?.root, {
          [styles.Button_fullWidth]: fullWidth,
          [styles.Button_rounded]: rounded
        })}
        style={{ width: `${width}px`, height: `${height}px` }}
      >
        {children}
      </button>
      {!noShadow &&
        <div
          className={classNames(styles.ButtonShadow, {
            [styles.ButtonShadow_open]: !!hovered.value
          })}
        />
      }
    </Link>
  ) : (
    <div
      className={classNames(styles.ButtonWrapper, classes?.wrapper, {
        [styles.Button_light]: light,
        [styles.Button_fullWidth]: fullWidth
      })}
    >
      <button
        onMouseEnter={hovered.set}
        onMouseLeave={hovered.unset}
        type={htmlType}
        onClick={onClick}
        className={classNames(classes?.root, styles.Root, {
          [styles.Button_fullWidth]: fullWidth,
          [styles.Button_rounded]: rounded
        })}
        style={{ width: `${width}px`, height: `${height}px` }}
        disabled={disabled}
      >
        {children}
      </button>
      {!noShadow &&
        <div
          className={classNames(styles.ButtonShadow, {
            [styles.ButtonShadow_open]: !!hovered.value
          })}
        />
      }
    </div>
  );
};
export default Button;
