import {
  SET_ACTIVITIES_LOADING,
  SET_ACTIVITIES_ERROR,
  SET_ACTIVITIES_SUCCESS
} from './actionTypes';

export const initialState = {
  loading: false,
  error: null,
  page_number: 1,
  page_size: 20,
  total_entries: 0,
  total_pages: 1,
  entries: []
};

const activitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVITIES_LOADING:
      return { ...state, loading: action.loading };
    case SET_ACTIVITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error?.response || action.error || null
      };
    case SET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload
      };
    default:
      return state;
  }
};
export default activitiesReducer;
