// @flow

/* eslint-disable */

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import activities from '@reducers/activities';
import themes from '@reducers/themes';
import hashtags from '@reducers/hashtags';
import activitiesConstructor from '@reducers/activitiesConstructor';
import activitiesStepsConstructor from './reducers/activitiesStepsConstructor';
import transferCourseRequest from './reducers/transferCourseRequest';

import users from '@reducers/users';

const reducer = combineReducers({
  form,
  activities,
  themes,
  hashtags,
  activitiesConstructor,
  activitiesStepsConstructor,
  transferCourseRequest,
  users
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export default () =>
  createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
