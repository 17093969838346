import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { logoutHelper } from '@reducers/users/actions';
// Assets
import CloseIcon from '@assets/icons/close.svg';
// Components
import Logo from '@components/Logo';
import Button from '@components/Button';

// Styles
import styles from './styles.scss';

// Constants
import { ROUTES } from '../../../../utils/constants'

const Menu = ({ open, onClose, courses }) => {
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.users.authorized);

  return (
    <div className={classNames(styles.Wrapper, { [styles.WrapperOpen]: open })}>
      <div className={styles.Header}>
        <Logo color="black" />
        <img onClick={onClose} src={CloseIcon} alt="" />
      </div>
      <div className={styles.NavItems}>
        {courses.map(n =>
          n?.url ? (
            <Link to={n.url} onClick={() => {
              if (window.location.pathname === "/courses" && n.text === 'Рекомендации') {
                onClose();
              }
            }} className={styles.NavItem} key={n.text} target={n.target || '_self'}>
              {n.text}
              {n?.count >= 0 ? <div className={styles.Counter}>{n.count}</div> : null}
            </Link>
          ) : (
            <a
              href={n.hash}
              onClick={onClose}
              className={styles.NavItem}
              key={n.text}
              target={n.target || '_self'}
            >
              {n.text}
            </a>
          )
        )}
      </div>
      <div className={styles.Buttons}>
        {isAuth && (
          <a
            href={ROUTES?.profile}
            onClick={onClose}
            className={classNames(
              styles.LaunchBusinessButtonWrapper,
              styles.ButtonWrapper
            )}
          >
            <Button
              light
              classes={{ wrapper: styles.ButtonWrapper, root: styles.Button }}
            >
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: '0 12px 0 0'}}>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.99993 9.27758C7.79528 9.27758 6.00581 7.49003 6.00581 5.28879C6.00581 3.08754 7.79528 1.3 9.99993 1.3C12.2046 1.3 13.994 3.08754 13.994 5.28879C13.994 7.49003 12.2046 9.27758 9.99993 9.27758ZM4.70581 5.28879C4.70581 2.36787 7.07901 0 9.99993 0C12.9208 0 15.294 2.36787 15.294 5.28879C15.294 8.2097 12.9208 10.5776 9.99993 10.5776C7.07901 10.5776 4.70581 8.2097 4.70581 5.28879ZM20 21.7195C20 21.3752 19.9826 21.0351 19.9486 20.6998C19.4378 15.6559 15.1786 11.7195 10 11.7195C4.8214 11.7195 0.562198 15.6559 0.0513531 20.6998C0.0173947 21.0351 0 21.3752 0 21.7195V21.9998H1.3H18.7H20V21.7195ZM10 13.0195C5.54009 13.0195 1.86399 16.3754 1.35913 20.6998H18.6409C18.136 16.3754 14.4599 13.0195 10 13.0195Z" fill="#1742F0"/>
              </svg>
              Профиль
            </Button>
          </a>
        )}
        <a
          href={isAuth ? '/creator/my-courses' : ROUTES?.register}
          onClick={onClose}
          className={classNames(
            styles.LaunchBusinessButtonWrapper,
            styles.ButtonWrapper
          )}
        >
          <Button
            classes={{ wrapper: styles.ButtonWrapper, root: styles.Button }}
          >
            {isAuth ? 'Мои курсы' : 'Запустить курс'}
          </Button>
        </a>
        {isAuth ? (
          <Button
            onClick={async() => await dispatch(logoutHelper())}
            classes={{
              wrapper: styles.ButtonWrapper,
              root: styles.Button
            }}
          >
            Выйти из аккаунта
          </Button>
        ) : (
          <Button
            to={ROUTES?.login}
            classes={{
              wrapper: styles.ButtonWrapper,
              root: styles.Button
            }}
            light
          >
            Войти
          </Button>
        )}
      </div>
    </div>
  );
};
export default Menu;
