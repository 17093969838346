import { instance } from '.';

const usersApi = {
  async login(data) {
    const res = await instance.post(`/sign_in`, data);
    return res?.data;
  },
  async getProfile() {
    const res = await instance.get(`/profile`);
    return res?.data;
  },
  // eslint-disable-next-line
  async oauth(data) {
    const res = await instance.post(`/oauth`, data);
    return res?.data;
  }
};
export default usersApi;
