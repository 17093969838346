import { instance } from './';

const activitiesApi = {
  async getItems({
    my,
    i_participate,
    status,
    statuses,
    related_to_activity,
    tab,
    paid_activity,
    theme_id,
    q,
    favorites,
    page,
    page_size
  }) {
    const searchMyParams = my ? `&search[my]=${my}` : '';
    const searcIhParticipateParams = i_participate
      ? `&search[i_participate]=${i_participate}`
      : '';
    const searchStatusParams = status ? `&search[status]=${status}` : '';
    const searchStatusesParams = statuses
      ? `&search[statuses][]=${statuses}`
      : '';
    const searchRelatedToActivityParams = related_to_activity
      ? `&search[related_to_activity]=${related_to_activity}`
      : '';
    const searchActivityTypeParams =
      tab === 'all' || paid_activity === undefined
        ? ''
        : `&search[paid_activity]=${paid_activity}`;
    const searchThemeIdParams = theme_id ? `&search[theme_id]=${theme_id}` : '';
    const searchQParams = q ? `&search[q]=${q}` : '';
    const searchFavoritesParams = favorites
      ? `&search[favorites]=${favorites}`
      : '';
    const res = await instance.get(
      `/activities?search[statuses][]=active&search[statuses][]=published&page=${page}&page_size=${page_size}${searchMyParams}${searcIhParticipateParams}${searchStatusParams}${searchStatusesParams}${searchRelatedToActivityParams}${searchActivityTypeParams}${searchThemeIdParams}${searchQParams}${searchFavoritesParams}`
    );
    return res.data;
  },
  async getItem(id) {
    const res = await instance.get(`/activities/${id}`);
    return res.data;
  },
  async pay({ id, return_url, promo_code }) {
    return await instance.post(`/activities/${id}/reserve_participation`, {promo_code})
      .then(async res => {
        const yookassa_pay_res = await instance.post(`/activities/${id}/yookassa_pay`, {return_url});
        return yookassa_pay_res?.data;
      })
    .catch(err => {
      console.error('err', err);
      return JSON.parse(JSON.stringify(err));
    })
  },
  async participate(id, data) {
    return await instance.post(`/activities/${id}/participate`, data)
      .then(async res => res?.data)
    .catch(err => {
      console.error('err', err);
      return JSON.parse(JSON.stringify(err));
    })
  }
};
export default activitiesApi;
