// @flow
import React, { useEffect, lazy, Suspense, createContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
// Actinos
import { getUserProfile, setUsersAuthorized } from './reducers/users/actions';
// Styles
import styles from './App.scss';
// Constants
import { ROUTES } from './utils/constants';
// Hooks
import useListenLocation from './hooks/useListenLocation';

// Pages
const UserLayout = lazy(() => import('./pages/UserLayout'));
const ProtectedLayout = lazy(() => import('./pages/ProtectedLayout'));
const HomePage = lazy(() => import('./pages/Home'));
const ActivitiesPage = lazy(() => import('./pages/Activities'));
const ActivityPage = lazy(() => import('./pages/Activity'));
const ActivityPaidSuccess = lazy(() => import('./pages/ActivityPaidSuccessPage'));
const AppPage = lazy(() => import('./pages/AppPage'));
const ActivityPageOverview = lazy(() => import('./pages/ActivityOverview'));
const ActivityTournamentTablePage = lazy(() => import('./pages/ActivityTournamentTablePage'));
const ActivityAboutShort = lazy(() => import('./pages/ActivityAboutShort'));
const ActivityAcceptWithdraw = lazy(() => import('./pages/ActivityAcceptWithdraw'));
const MyActivitiesPage = lazy(() => import('./pages/MyActivities'));
const RegisterPage = lazy(() => import('./pages/Register'));
const LoginPage = lazy(() => import('./pages/Login'));
const ConfirmMailPage = lazy(() => import('./pages/ConfirmMail'));
const PaymentSuccessPage = lazy(() => import('./pages/PaymentSuccess'));
const JoinSuccessPage = lazy(() => import('./pages/JoinSuccess'));
const PaymentDetailsPage = lazy(() => import('./pages/PaymentDetails'));
const EmailConfirmedPage = lazy(() => import('./pages/EmailConfirmed'));
const RecoveryPage = lazy(() => import('./pages/RecoveryPasswordPage'));
const ResetPage = lazy(() => import('./pages/ResetPasswordPage'));
const ResetEmailPage = lazy(() => import('./pages/ResetEmailPage'));
const AddRequisitePage = lazy(() => import('./pages/AddRequisite/addRequisite'));
const EditRequisitePage = lazy(() => import('./pages/EditRequisite/editRequisite'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsOfBuyActivitiesPage = lazy(() => import('./pages/TermsOfBuyActivitiesPage'));
const TermsOfUsePage = lazy(() => import('./pages/TermsOfUsePage'));
const ProfilePage = lazy(() => import('./pages/Profile'));
const ProfileShowPage = lazy(() => import('./pages/ProfileShow'));
const ProfileEditPage = lazy(() => import('./pages/ProfileEdit'));
const ResetPasswordProfilePage = lazy(() => import('./pages/ResetPasswordProfilePage'));
const Page500 = lazy(() => import('./pages/Page500'));
const Page404 = lazy(() => import('./pages/Page404'));

// Creators flow
const MyCourses = lazy(() => import('./pages/ForCreators/MyCourses'));
const ChoiseType = lazy(() => import('./pages/ForCreators/ChoiseType'));
const LandingConstructor = lazy(() => import('./pages/ForCreators/LandingConstructor'));
const StepSettings = lazy(() => import('./pages/ForCreators/StepsSettings'));
const StepsCreator = lazy(() => import('./pages/ForCreators/StepsCreator'));
const DraftActivity = lazy(() => import('./pages/ForCreators/DraftActivity'));
const DraftActivitySettings = lazy(() => import('./pages/ForCreators/DraftActivitySettings'));
const MyAuthorInformation = lazy(() => import('./pages/ForCreators/MyAuthorData'));
const AuthorInformation = lazy(() => import('./pages/ForCreators/AuthorInformation/authorInformation'));
const ChooseRequesite = lazy(() => import('./pages/ForCreators/ChooseRequesite'));
const CheckSettings = lazy(() => import('./pages/ForCreators/CheckSettings'));
const AlmostDone = lazy(() => import('./pages/ForCreators/AlmostDone'));
const Done = lazy(() => import('./pages/ForCreators/Done'));

// TODO: сделать нормальный лоадер и вынести в ui
const PageLoader = () => <></>

export const LocationArrContext = createContext();

const App = () => {

  const dispatch = useDispatch();
  const { locationArr } = useListenLocation();

  const authorized = Cookies.get('authToken');

  useEffect(() => {
    if (authorized) {
      dispatch(setUsersAuthorized(true));
      dispatch(getUserProfile());
    }
  }, [authorized, dispatch]);

  return (
    <div className={styles.Root}>
      <LocationArrContext.Provider value={locationArr}>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            // TODO: руты на которые нет ссылок в приложении (отдельно обсудить что из них нужно)
            <Route path={ROUTES.activitiesConfirm} element={<ConfirmMailPage />}/>
            <Route path={ROUTES.activitiesPaymentSuccess} element={<PaymentSuccessPage />}/>
            <Route path={ROUTES.activitiesJoinSuccess} element={<JoinSuccessPage />}/>
            <Route path={ROUTES.resetPassword} element={<ResetPage />}/>
            <Route path={ROUTES.profileResetPass} element={<ResetPasswordProfilePage />}/>
            <Route path={ROUTES.myCourses} element={<MyActivitiesPage />}/>

            // TODO: руты на которые нет ссылок в приложении
            <Route path={ROUTES.home} element={<HomePage />}/>
            <Route path={ROUTES.courses} element={<ActivitiesPage />}/>
            <Route path={ROUTES.coursesById} element={<ActivityPage />}/>
            <Route path={ROUTES.coursesByIdOverview} element={<ActivityPageOverview />}/>
            <Route path={ROUTES.coursesByIdAboutShort} element={<ActivityAboutShort/>}/>
            <Route path={ROUTES.coursesByIdAcceptWithdraw} element={<ActivityAcceptWithdraw/>}/>
            <Route path={ROUTES.coursesSuccessPayment} element={<ActivityPaidSuccess />}/>
            <Route path={ROUTES.coursesTournamentTable} element={<ActivityTournamentTablePage />}/>
            <Route path={ROUTES.apps} element={<AppPage />}/>
            <Route path={ROUTES.profileShow} element={<ProfileShowPage />}/>


            <Route element={<ProtectedLayout />}>
              <Route path={ROUTES.profile} element={<ProfilePage />}/>
              <Route path={ROUTES.profileEdit} element={<ProfileEditPage />}/>
              <Route path={ROUTES.activitiesPaymentDetailsById} element={<PaymentDetailsPage />}/>
              <Route path={ROUTES.resetEmail} element={<ResetEmailPage />}/>
              <Route path={ROUTES.addRequisite} element={<AddRequisitePage />}/>
              <Route path={ROUTES.editRequisite} element={<EditRequisitePage />}/>

              // TODO: роуты для flow создания курсов
              <Route path={ROUTES.forCreator.myCourses} element={<MyCourses />}/>
              <Route path={ROUTES.forCreator.myCoursesNew} element={<ChoiseType />}/>
              <Route path={ROUTES.forCreator.newMarathon} element={<LandingConstructor />}/>
              <Route path={ROUTES.forCreator.newCourse} element={<LandingConstructor />}/>
              <Route path={ROUTES.forCreator.draftActivityEdit} element={<LandingConstructor />} />
              <Route path={ROUTES.forCreator.stepsSettings} element={<StepSettings />} />
              <Route path={ROUTES.forCreator?.stepsCreator} element={<StepsCreator />}/>
              <Route path={ROUTES.forCreator.draftActivity} element={<DraftActivity />} />
              <Route path={ROUTES.forCreator.draftActivitySettings} element={<DraftActivitySettings />} />
              <Route path={ROUTES.forCreator.draftActivityAuthor} element={<MyAuthorInformation />}/>
              <Route path={ROUTES.forCreator.draftActivityAuthorNew} element={<AuthorInformation />}/>
              <Route path={ROUTES.forCreator.draftActivityAuthorEdit} element={<AuthorInformation />}/>
              <Route path={ROUTES.forCreator.chooseRequesite} element={<ChooseRequesite />}/>
              <Route path={ROUTES.forCreator.checkPaymentSettingsNew} element={<CheckSettings />}/>
              <Route path={ROUTES.forCreator.almostDone} element={<AlmostDone />}/>
              <Route path={ROUTES.forCreator.done} element={<Done />}/>
            </Route>

            <Route element={<UserLayout />}>
              <Route path={ROUTES.register} element={<RegisterPage />}/>
              <Route path={ROUTES.login} element={<LoginPage />}/>
              <Route path={ROUTES.recovery} element={<RecoveryPage />}/>
              <Route path={ROUTES.verify} element={<EmailConfirmedPage />}/>
            </Route>

            {/* Роуты на доки */}
            <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage />}/>
            <Route path={ROUTES.termsOfBuyActivities} element={<TermsOfBuyActivitiesPage />}/>
            <Route path={ROUTES.termsOfUse} element={<TermsOfUsePage />}/>
            <Route path={ROUTES.error500} element={<Page500 />} />
            <Route path={'*'} element={<Page404 />} />

          </Routes>
        </Suspense>

      </LocationArrContext.Provider>
    </div>
  );
};

export default App;
