import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
// Api
import activitiesApi from '@api/activities';
// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
// Styles
import styles from './styles.scss';

const MainLayout = ({
  children,
  headerColor,
  whiteHeaderBg,
  className,
  headerClassName,
  containerClassName,
}) => {
  const [totalEntries, setTotalEntries] = useState(0);

  const fetchData = async () => {
      const data = await activitiesApi.getItems({});
      setTotalEntries(data?.total_entries);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classNames(styles.Wrapper, className)}>
      <Header coursesCount={totalEntries} color={headerColor} className={headerClassName} whiteHeaderBg={whiteHeaderBg} />
      <div className={classNames(styles.Container, containerClassName)}>
        {children}
      </div>
      <Footer />
    </div>
  );
};
export default MainLayout;
