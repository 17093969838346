import {
  SET_STEPS_CONSTRUCTOR_LOADING,
  SET_STEPS_CONSTRUCTOR_ERROR,
  SET_STEPS_CONSTRUCTOR_SUCCESS,
  SET_STEPS_CONSTRUCTOR_EDITOR_LOADING,
  SET_STEPS_CONSTRUCTOR_EDITOR_ERROR,
  SET_STEPS_CONSTRUCTOR_EDITOR_SUCCESS
} from './actionTypes';

export const initialState = {
  steps_constructor_loading: false,
  steps_constructor_error: null,
  steps_activity: {},
  steps_constructor_editor_loading: false,
  steps_constructor_editor_error: null,
};

const activitiesStepsConstructorReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SET_STEPS_CONSTRUCTOR_LOADING:
      return { ...state, steps_constructor_loading: action?.loading };
    case SET_STEPS_CONSTRUCTOR_ERROR:
      return {
        ...state,
        steps_constructor_loading: false,
        steps_constructor_error: action?.error?.response || action?.error || null
      };
    case SET_STEPS_CONSTRUCTOR_SUCCESS:
      return {
        ...state,
        steps_constructor_loading: false,
        steps_constructor_error: null,
        steps_activity: action?.payload
      };
      case SET_STEPS_CONSTRUCTOR_EDITOR_LOADING:
        return { ...state, steps_constructor_editor_loading: action?.loading };
      case SET_STEPS_CONSTRUCTOR_EDITOR_ERROR:
        return {
          ...state,
          steps_constructor_editor_loading: false,
          steps_constructor_editor_error: action?.error?.response || action?.error || null
        };
      case SET_STEPS_CONSTRUCTOR_EDITOR_SUCCESS:
        return {
          ...state,
          steps_constructor_editor_loading: false,
          steps_constructor_editor_error: null,
        };
    default:
      return state;
  }
};
export default activitiesStepsConstructorReducer;
