import {
  SET_USERS_REGISTER_WITH_CONTENT,
  SET_USERS_LOADING,
  SET_USERS_ERROR, 
  SET_USERS_AUTHORIZED,
  SET_USERS_PROFILE
} from './actionTypes';

export const initialState = {
  loading: false,
  error: null,
  entries: [],
  registerWithContent: false,
  authorized: false,
  user: {}
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_LOADING:
      return { ...state, loading: action.loading };
    case SET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error?.response || action.error || null
      };
    case SET_USERS_REGISTER_WITH_CONTENT:
      return {
        ...state,
        registerWithContent: action.value
      };
    case SET_USERS_AUTHORIZED:
      return {
        ...state,
        authorized: action.payload
      }
    case SET_USERS_PROFILE:
      return {
        ...state,
        user: action.payload
      }
    default:
      return state;
  }
};
export default usersReducer;
