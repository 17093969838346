import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import MainLayout from '@layouts/MainLayout';
import Apps from '@components/Apps';
import descktopMock from './assets/desktopQR.png';
import mobileMock from './assets/mobileMock.png';
import {instance} from "../../api";
import {ROUTES} from "../../utils/constants";
import Loader from "../../components/Loader";
import styles from './styles.scss';

export const ActivityPaidSuccess = ({ isDesktop }) => {
  const { activityId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  const getActivity = async () => {
    await instance.get(`/activities/${activityId}`)
    .then(res => {
      if (res.data.paid_activity) {
        if (res.data.is_participating ) {
          setLoading(false);
        } else {
          navigate(`${ROUTES?.courses}/${activityId}`);
        }
      } else {
        setLoading(false);
      }
    })
    .catch(err => console.error(err));
  };

  useEffect(() => {
    getActivity();
  }, []);

  return (
    <MainLayout headerColor="black" className={styles.Wrapper}>
      {isLoading ? <Loader/> : (
        <div className={styles.ActivityPaidSuccess_root}>
          { isDesktop
            ? (<img className={styles.ActivityPaidSuccess_qr} src={descktopMock} alt=""/>)
            : (<img className={styles.ActivityPaidSuccess_mobileMock} src={mobileMock} alt=""/>)
          }
          <p className={styles.ActivityPaidSuccess_title}>Вы успешно вступили в активность. Вы можете участвовать
            в активности в мобильном приложении</p>
          <p className={styles.ActivityPaidSuccess_text}>
            {isDesktop
              ? " Активность будет доступна в разделе «Я участвую». Для скачивания приложения отсканируйте QR-код с мобильного устройства."
              : "Активность будет доступна в разделе «Я участвую». Для скачивания приложения перейдите по ссылке:"
            }
          </p>
          {!isDesktop && (
            <Apps
              className={styles.ActivityPaidSuccess_icons}
              iconClassName={styles.ActivityPaidSuccess_icon}
            />
          )}
        </div>
      )}
    </MainLayout>
  );
};
