// @flow

// Library
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

const withBreakpoints = (Component) => (
  props
) => {

  const isDesktop = useMediaQuery({ minWidth: 1201 });

  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1200 });

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Component
      {...props}
      isDesktop={isDesktop}
      isMobile={isMobile}
      isTablet={isTablet}
    />
  );
};

// Exports
export default withBreakpoints;
export { withBreakpoints };
