import {
  SET_THEMES_LOADING,
  SET_THEMES_ERROR,
  SET_THEMES_SUCCESS
} from './actionTypes';

export const initialState = {
  loading: false,
  error: null,
  entries: []
};

const themesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THEMES_LOADING:
      return { ...state, loading: action.loading };
    case SET_THEMES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error?.response || action.error || null
      };
    case SET_THEMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        entries: action.payload
      };
    default:
      return state;
  }
};
export default themesReducer;
