import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { YMInitializer } from 'react-yandex-metrika';

// App
import App from './App';

// Store
import createStore from './store';

// Utils
import * as serviceWorker from '@utils/serviceWorker';

const $root = document.getElementById('root');
const store = createStore();

$root &&
ReactDOM.render(
  <Provider store={store}>
    {window?.__CONFIG__?.REACT_APP_ENABLE_METRICKS &&
      <YMInitializer
        accounts={[93255284]}
        options={{
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true,
          ecommerce:"dataLayer"
        }}
      />
    }
    <GoogleReCaptchaProvider
      reCaptchaKey={window?.__CONFIG__?.REACT_APP_RECAPTCHA_SITE_KEY}
      language={'RU'}
      useEnterprise={true}
    >
      <GoogleOAuthProvider clientId={window?.__CONFIG__?.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </GoogleReCaptchaProvider>
  </Provider>,
  $root
);

serviceWorker.unregister();
