import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { instance } from "../../api/index";

import usersApi from '@api/users';
import {
  SET_USERS_REGISTER_WITH_CONTENT,
  SET_USERS_LOADING,
  SET_USERS_ERROR,
  SET_USERS_AUTHORIZED,
  SET_USERS_PROFILE
} from './actionTypes';
import ym from "react-yandex-metrika";

export const setUsersLoading = loading => ({
  type: SET_USERS_LOADING,
  loading
});
export const setUsersError = error => ({
  type: SET_USERS_ERROR,
  error
});
export const setRegisterWithContent = value => ({
  type: SET_USERS_REGISTER_WITH_CONTENT,
  value
});
export const setUsersAuthorized = payload => ({
  type: SET_USERS_AUTHORIZED,
  payload
});
export const setUsersProfile = payload => ({
  type: SET_USERS_PROFILE,
  payload
});

export const login = data => async dispatch => {
  try {
    dispatch(setUsersLoading(true));

    const res = await usersApi?.login({...data, source: 'web'});

    dispatch(setUsersAuthorized(true));
    dispatch(setUsersProfile(res?.user));

    instance.interceptors.request.use((config) => {
      config.headers["auth-token"] = res?.token;
      return config;
    });
    Cookies.set('authToken', res?.token, {expires: 7});

    dispatch(setUsersLoading(false));

    return res;
  } catch (error) {
    dispatch(setUsersLoading(false));
    return error?.response?.data;
  }
};

export const getUserProfile = () => async dispatch => {
  try {
    dispatch(setUsersLoading(true));

    const res = await usersApi?.getProfile();

    dispatch(setUsersLoading(false));
    dispatch(setUsersProfile(res));
  } catch (error) {
    console.error(error);
    dispatch(setUsersLoading(false));
    dispatch(setUsersError(error));
  }
};

export const logout = () => dispatch => {
  dispatch(setUsersAuthorized(true));
  dispatch(setUsersProfile({}));
  Cookies.remove('authToken');
  window.location.pathname = '/';
};

// eslint-disable-next-line
export const oauth = (data, redirectURL, errorHandler, afterLoginHandler) => async dispatch => {
  try {
    dispatch(setUsersLoading(true));

    // eslint-disable-next-line
    const res = await usersApi?.oauth(data);

    dispatch(setUsersAuthorized(true));
    dispatch(setUsersProfile(res?.user));

    instance.interceptors.request.use((config) => {
      config.headers["auth-token"] = res?.token;
      return config;
    });
    Cookies.set('authToken', res?.token, {expires: 7});

    dispatch(setUsersLoading(false));

    if (afterLoginHandler) {
      afterLoginHandler();
    } else {
      if (redirectURL) {
        window.location.pathname = redirectURL;
      }
    }

    return res;
  } catch (error) {
    dispatch(setUsersLoading(false));
    if (errorHandler) {
      errorHandler();
      setTimeout(() => errorHandler(), 3000);
    }
    return error?.response?.data;
  }
};


export const onOauthBySocialButton = (data) => async dispatch => {
  const { provider, redirectURL, errorHandler, afterLoginHandler } = data;

  if (data?.error) {
    return;
  }

  let access_token = '';
  let tokenWithUserData = '';
  let first_name = '';
  let last_name = '';
  let avatar = '';

  if (provider === 'google') {
    access_token = data?.credential;
    tokenWithUserData = jwt_decode(data?.credential);
    first_name = tokenWithUserData?.given_name;
    last_name = tokenWithUserData?.family_name;
    // TODO: Да выглядит не очень, я знаю, сорри, но я чёт заебался
    localStorage.setItem('coacti_google_avatar', tokenWithUserData?.picture || null);
  }

  if (provider === 'apple_web') {
    access_token = data?.authorization?.code;
    tokenWithUserData = data?.authorization?.id_token;
    first_name = data?.user?.name?.firstName;
    last_name = data?.user?.name?.lastName;
  }

  // eslint-disable-next-line
  const res = await dispatch(oauth({ provider, access_token, first_name, last_name, avatar }, redirectURL, errorHandler, afterLoginHandler));
};

export const logoutHelper = () => async dispatch => {
  await dispatch(logout());
  localStorage.removeItem('coacti_google_avatar')
  window.location.reload(true);
};
