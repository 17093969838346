import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as LogoWhite } from "@assets/logo_white.svg";
import { ReactComponent as LogoBlue } from "@assets/logo_blue.svg";

// Styles
import styles from './styles.scss';

const Logo = ({ classes, color = 'white', homePageLogo }) => {
  return (
    <Link
      to="/"
      className={classNames(styles.Logo, {
        [styles.LogoWhite]: homePageLogo || color === 'white',
        [styles.LogoBlue]: color === 'black'
      })}
    >
      {!homePageLogo && color === 'white' ? <LogoWhite /> : null }
      {homePageLogo || color === 'black' ? <LogoBlue /> : null }
      <span className={classes?.text}>CоActivity</span>
    </Link>
  );
};
export default Logo;
