import {
  ADD_ACTIVITIES_CONSTRUCTOR_STEP,
  EDIT_ACTIVITIES_CONSTRUCTOR_STEP,
  SET_ACTIVITIES_CONSTRUCTOR_TAB,
  SET_ACTIVITIES_CONSTRUCTOR_CHOSEN_STEP
} from './actionTypes';

export const initialState = {
  steps: [
    {
      id: `${new Date() + 1}`,
      title: 'Как научиться рисовать акварелью?',
      duration: 6,
      text: 'Как научиться рисовать акварелью?',
      photos: [
        'https://i.pinimg.com/564x/3f/46/19/3f4619daa8b22e969c47654d0c0caae0.jpg',
        'https://i.pinimg.com/564x/41/63/e7/4163e76ef75ccaec2fa36723598f14bd.jpg',
        'https://i.pinimg.com/564x/73/22/51/73225121a85c2ed5b0fab2647c28f493.jpg'
      ]
    }
  ],
  chosenStep: null,
  constructorTab: '03'
};

const activitiesConstructorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACTIVITIES_CONSTRUCTOR_STEP:
      return {
        ...state,
        steps: [...state.steps, action.payload]
      };
    case EDIT_ACTIVITIES_CONSTRUCTOR_STEP:
      return {
        ...state,
        steps: state.steps.filter(s => {
          if (s.id === action.payload.id) {
            return action.payload;
          } else {
            return s;
          }
        })
      };
    case SET_ACTIVITIES_CONSTRUCTOR_CHOSEN_STEP:
      return {
        ...state,
        chosenStep: action.payload
      };
    case SET_ACTIVITIES_CONSTRUCTOR_TAB:
      return {
        ...state,
        constructorTab: action.payload
      };
    default:
      return state;
  }
};
export default activitiesConstructorReducer;
