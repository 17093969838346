import {
  SET_TRANSFER_COURSE_REQUEST_LOADING,
  SET_TRANSFER_COURSE_REQUEST_ERROR
} from './actionTypes';

export const initialState = {
  loading: false,
  error: null,
};

const transferCourseRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSFER_COURSE_REQUEST_LOADING:
      return { ...state, loading: action.loading };
    case SET_TRANSFER_COURSE_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error?.response || action.error || null
      };
    default:
      return state;
  }
};
export default transferCourseRequestReducer;
