import axios from 'axios';
import Cookies from 'js-cookie';

export const APP_TOKEN = '293d14d47f6abf51a5c44fe94f87883b';

export const instance = axios.create({
  // eslint-disable-next-line
  baseURL: `${window?.__CONFIG__?.REACT_APP_API}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    token: APP_TOKEN,
    'auth-token': Cookies.get('authToken')
  }
});
