import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// Utils
import { docsLinks } from '@utils/constants';

// Components
import Logo from '@components/Logo';

// Styles
import styles from './styles.scss';
import { withBreakpoints } from '@styles/breakpoints';
import classNames from 'classnames';

// Constants
import { ROUTES } from '../../utils/constants'

const Footer = ({ isTablet, isMobile }) => {
  const copyrightText = `© CoActivity, ${new Date()?.getFullYear()}. Все права защищены`;

  return (
    <div className={styles?.Wrapper}>
      <div className={styles?.Row}>
        <div className={styles?.TopContentWrapper}>
          <div className={styles?.LogoWithCokkies}>
            <Logo />
            {!isTablet && !isMobile && (
              <p className={styles?.CookiesText}>
                Мы используем <a href={ docsLinks?.terms_of_cookies?.link } target="_blank">
                  {docsLinks?.terms_of_cookies?.name}
                </a> для персонализации сервисов и повышения удобства пользования сайтом. Если вы не согласны на их использование, поменяйте настройки браузера.
              </p>
            )}
          </div>
          <div className={styles?.NavItems}>
            <div className={styles?.NavItemsCol}>
              <p className={styles?.NavItemsTitle}>Курсы</p>
              <div className={styles?.NavItemsContainer}>
                <Link
                  to={ROUTES?.courses}
                >
                  Все курсы
                </Link>
                <HashLink
                  to={ROUTES?.launchCourse}
                  smooth
                >
                  Запустить курс
                </HashLink>
              </div>
            </div>
            <div className={styles?.NavItemsCol}>
              <p className={styles?.NavItemsTitle}>Остались вопросы?</p>
              <div className={styles?.NavItemsContainer}>
                <a href="mailto:help@coacti.com" >help@coacti.com</a>
                <p>Напишите на нашу почту — на всё ответим</p>
              </div>
            </div>
          </div>
        </div>
        {(isTablet || isMobile) && (
          <p className={styles?.CookiesText}>
            Мы используем <a href={ docsLinks?.terms_of_cookies?.link } target="_blank">
              {docsLinks?.terms_of_cookies?.name}
            </a> для персонализации сервисов и повышения удобства пользования сайтом. Если вы не согласны на их использование, поменяйте настройки браузера.
          </p>
        )}
      </div>
      <div className={styles?.Row}>
        {/* {!isTablet && !isMobile && ( */}
          <div className={styles?.AllRights}>
            {copyrightText}
          </div>
        {/* )} */}
        <div className={styles?.Docs}>
          <a href={ docsLinks?.terms_of_use?.link } target="_blank" className={styles?.Doc}>
            {docsLinks?.terms_of_use?.name}
          </a>
          <a href={ docsLinks?.privacy_policy?.link } target="_blank" className={styles?.Doc}>
            {docsLinks?.privacy_policy?.name}
          </a>
          <a href={ docsLinks?.terms_of_buy_activities?.link } target="_blank" className={styles?.Doc}>
            {docsLinks?.terms_of_buy_activities?.name}
          </a>
        </div>
        {/* {(isTablet || isMobile) && (
          <div className={styles?.AllRights}>
            {copyrightText}
          </div>
        )} */}
      </div>
    </div>
  );
};
export default withBreakpoints(Footer);
