import React from "react";
import styles from './styles.scss';

const Loader = () => {
  return (
    <div className={styles.Root}>
      <div className={styles.Ripple}/>
    </div>
  )
};

export default Loader;
