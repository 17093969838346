import React, { useState } from 'react';
import classNames from 'classnames';
// Assets
import AppStoreDarkIcon from '@assets/icons/app_store_black.svg';
import AppStoreIcon from '@assets/icons/app_store.svg';
import GooglePlayDarkIcon from '@assets/icons/google_play_black.svg';
import GooglePlayIcon from '@assets/icons/google_play.svg';
import { APPLE_APP_LINK, GOOGLE_APP_LINK } from '../../utils/constants';
import ym from 'react-yandex-metrika';

// Styles
import styles from './styles.scss';


// TODO: GooglePlayBlockedIcon - выпилить и расскоментировать нужный код, кргда будет линк на google play
export const Apps = ({ color = 'dark', className, iconClassName }) => {
  const [hovered, setHovered] = useState(null);

  const handleChangeHovered = value => () => {
    setHovered(value);
  };

  return (
    <div className={classNames(styles.Apps, className)}>
      <a
        onMouseEnter={handleChangeHovered('apple')}
        onMouseLeave={handleChangeHovered(null)}
        href={APPLE_APP_LINK}
        onClick={() => ym('reachGoal', 'download_app_apple')}
        className={classNames(styles.Apps__Button, styles.Apps__ButtonApple, iconClassName)}
        target="_blank"
      >
        <img
          src={color === 'dark' ? AppStoreDarkIcon : AppStoreIcon}
          alt="app_store_icon"
          className={styles.Apps__ButtonIcon}
        />
        <div
          className={classNames(styles.Apps__ButtonShadow, {
            [styles.Apps__ButtonShadow_open]: hovered === 'apple'
          })}
        />
      </a>
      <a
        onMouseEnter={handleChangeHovered('google')}
        onMouseLeave={handleChangeHovered(null)}
        href={GOOGLE_APP_LINK}
        onClick={() => ym('reachGoal', 'download_app_google')}
        className={classNames(styles.Apps__Button, iconClassName)}
        target="_blank"
      >
        <img
          src={color === 'dark' ? GooglePlayDarkIcon : GooglePlayIcon}
          alt="google_store_icon"
          className={classNames(styles.Apps__ButtonIcon)}
        />
        <div
          className={classNames(styles.Apps__ButtonShadow, {
            [styles.Apps__ButtonShadow_open]: hovered === 'google'
          })}
        />
      </a>
    </div>
  );
};
export default Apps;
